import { loadStripe, Stripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from 'src/env.json';

declare global {
  interface Window {
    stripeClient: Stripe | null;
  }
}

export const module = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const load = async () => {
  window.stripeClient = await module;
};
